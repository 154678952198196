<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="supplierprice">
        <div class="supplierprice_tit">
            <div class="supplierprice_tit_box_left">
                <div class="supplierprice_tit_box_left_lishi">
                    <p>
                        <img src="../../../assets/images/lishichaxun.png" alt="">
                        历史查询
                    </p>
                    <div class="rli_box">
                        <el-calendar class="supplierprice_rli" @click.native='dianji_xuanze_rli' ref="rli">
                            <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
                            <template
                                slot="dateCell"
                                slot-scope="{date, data}"
                                >
                                <div class="text">{{ data.day.split('-').slice(2).join('') }}
                                    <div v-for="(item,index) in rqi_list" :key="index">
                                        <span class="lvse" v-if="data.day==item"></span>
                                        <!-- <div v-if="(item.months).indexOf(data.day.split('-').slice(1)[0])!=-1">
                                            <div v-if="(item.days).indexOf(data.day.split('-').slice(2).join('-'))!=-1">
                                                <span></span>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </template>
                        </el-calendar>
                    </div>
                </div>
                <p v-if="lishi_rqi.length==0" class="btn" @click="gongying_shang=true">选择供应商</p>
                <p v-if="lishi_rqi.length==0" class="btn" @click="biaoqian_shezhi">标签设置</p>
                <p v-if="lishi_rqi.length==0" class="btn zise" @click="dianji_baocun_baojia">保存</p>
                <p v-if="lishi_rqi.length!=0" style="margin-left:0.2rem">当前日期:{{lishi_rqi}}</p>
                <p v-if="lishi_rqi.length!=0" @click="lishi_rqi=''" class="btn zise">退出历史查询</p>
                <p v-if="lishi_rqi.length==0" @click="dangqian_zhuangtai_num=2" :class="dangqian_zhuangtai_num==2?'btn zise':'btn'">看自己</p>
                <p v-if="lishi_rqi.length==0&&guanli" @click="dangqian_zhuangtai_num=1" :class="dangqian_zhuangtai_num==1?'btn zise':'btn'">看员工</p>
                <p v-if="lishi_rqi.length==0&&guanli" @click="dangqian_zhuangtai_num=3" :class="dangqian_zhuangtai_num==3?'btn zise':'btn'">看全部</p>
            </div>
            <p class="supplierprice_tit_box_right">
                <i class="el-icon-search"></i>
                <input type="text" placeholder="搜索供应商、产品名称" v-model="sousuo_text" @keyup.enter="liebiao_jiekou()">
            </p>
        </div>
        <div class="gongying_shang_table">
            <el-table
                ref="gongying_shang_ref"
                :data="gongying_shang_list"
                :cell-style="liebiao"
                :header-cell-style="biaotou"
                tooltip-effect="dark"
                height="6.5rem"
                style="width: 100%;font-size: 14px"
                @selection-change="xuanzhong">
                <el-table-column
                    type="selection"
                    width="77">
                </el-table-column>
                <el-table-column v-for="(i,index) in biao_tit" :key="index"
                    :prop="i.con"
                    :label="i.name"
                    min-width="150"
                    >
                    <template slot-scope="props">
                        <p v-if="i.con!='quoted_price'">{{props.row[i.con]}}</p>
                        <span v-if="(props.row[i.con]==undefined||props.row[i.con].length==0)&&i.con!='quoted_price'">—</span>
                        <span v-if="lishi_rqi.length!=0&&i.con=='quoted_price'">{{props.row[i.con].length!=0?props.row[i.con]:'暂无数据'}}</span>
                        <input  :disabled='$jichuxinxi().user_id!=props.row.staff_id' placeholder="暂无数据" style="border:none;text-align:right;" v-if="i.con=='quoted_price'&&lishi_rqi.length==0" type="number" v-model="props.row[i.con]">
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination
            @size-change="dianji_tiaoshu"
            @current-change="dianji_yeshu"
            :current-page="dangqian_yeshu"
            class="fenye_qi"
            :page-sizes="[10, 20, 30, 40,50,60,70,80,90,100]"
            :page-size='tiaoshu'
            layout="total,sizes, prev, pager, next, jumper"
            :total="dangqian_zongshu">
        </el-pagination>
        <div class='beijing' style="z-index:101;" v-if='chanpin' @click="chanpin=false">
            <div class="chanpin_leixing_box" @click.stop>
                <i class="el-icon-error guanbi_xuanze_chanpin" @click="chanpin=false"></i>
                <div class='beijing_box_tit'>
                    产品分类：
                    <el-select v-model="xuanze_chanpin_id" clearable @change="xuanze_chanpin_dalei" class='shiyong_biaodan_xiala_kuang' placeholder="请选择">
                        <el-option
                        v-for="item in qiye_chanpin_leixing_list"
                        :key="item.cat_id"
                        :label="item.cat_name"
                        :value="item.cat_id">
                        </el-option>
                    </el-select>
                    搜索：
                    <p class="shiyong_sousuo_chanpin">
                        <i class="el-icon-search"></i>
                        <input v-model="sousuo_chanpin_text" @keyup.enter="chanpin_sousuo()" type="text">
                        <i @click="sousuo_chanpin_text='',chanpin_sousuo()" class='el-icon-circle-close'></i>
                    </p>
                    <span class="shiyong_chanpin_queding" @click="dianji_xuanze_chanpin_baocun()">确定</span>
                </div>
                <!-- <ul>
                    <li v-for="i in yixuan_chanpin" :key="i.prod_id">{{i.name}}</li>
                </ul> -->
                <ul class="mingxi_chanpin_yangshi">
                    <li v-for="(i,index) in yixuan_chanpin" :key="i.prod_id">
                        <i @click="shanchu_yixuan_chanpin(i,index)" class="el-icon-error"></i>
                        {{i.prod_name}}
                    </li>
                </ul>
                <div class='beijing_box_table'>
                    <!-- @selection-change="handleSelectionChange"  -->
                    <!-- @current-change="chooseMcaterialChange" -->
                    <el-table :cell-style="liebiao_yangshi" height="500" :header-cell-style="biaotou_yangshi"
                        :data="chanpin_liebiao_list"
                        highlight-current-row
                        ref="Tableb"
                        style="width: 100%"
                        @select = "xuanze_chanpin"
                        @select-all = "xuanze_chanpin_quanxuan"
                        >
                        <el-table-column  type="selection" width="42">
                        </el-table-column>
                        <el-table-column  prop="prod_code" label="产品编号">
                        </el-table-column>
                        <el-table-column  prop="prod_name" label="产品">
                        </el-table-column>
                        <el-table-column prop="cat_name"  label="类型">
                        </el-table-column>
                        <el-table-column  prop="spec" label="规格">
                        </el-table-column>
                        <el-table-column  prop="type" label="型号">
                        </el-table-column>
                        <el-table-column  prop="unit" label="单位">
                        </el-table-column>
                        <el-table-column  prop="price_basic" label="初始价">
                        </el-table-column>
                        <!-- <el-table-column  prop="qty" label="数量">
                        </el-table-column> -->
                        <!-- <el-table-column  prop="price_cost" label="成本" width="80">
                        </el-table-column>
                        <el-table-column  prop="price_sale" label="售价" width="80">
                        </el-table-column> -->
                        <el-table-column  prop="disc_rate" label="调价率">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div class='beijing' v-if="gongying_shang">
            <div class="gongying_shang_box" @click.stop>
                <p class="gongying_shang_box_tit">供应商选择</p>
                <ul>
                    <li v-for="(item,index) in gongying_shang_liebiao" @click="gongying_shang_num=index" :key="item.supply_id">
                        <!-- <img :src="item.zhi?require('../../../assets/okblue.png'):require('../../../assets/noccc.png')" alt=""> -->
                        <img :src="gongying_shang_num==index?require('../../../assets/me/xuan_zi.png'):require('../../../assets/me/weixuan_zi.png')" alt="">
                        {{item.supply_name}}
                    </li>
                </ul>
                <div class="foot">
                    <p @click="dianji_queding_gongying_shang">设置标签</p>
                    <p @click="gongying_shang=false">取消</p>
                </div>
            </div>
        </div>
        <div class='beijing' style="z-index:102;" v-if="baojia_tianxie">
            <div class="chanpin_leixing_box" @click.stop>
                <p class="gongying_shang_box_tit">报价修改</p>
                <div class='beijing_box_table' style="padding:0 0.1rem;">
                    <!-- @selection-change="handleSelectionChange"  -->
                    <!-- @current-change="chooseMcaterialChange" -->
                    <el-table height="500"
                        :cell-style="liebiao"
                        :header-cell-style="biaotou"
                        :data="dangqian_gongying_shang_chanpin_list"
                        highlight-current-row
                        ref="Tableb"
                        style="width: 100%"
                        >
                        <el-table-column  prop="supply_name" label="供应商">
                        </el-table-column>
                        <el-table-column  prop="prod_code" label="产品编号">
                        </el-table-column>
                        <el-table-column  prop="prod_name" label="产品名称">
                        </el-table-column>
                        <el-table-column  prop="spec" label="规格">
                        </el-table-column>
                        <el-table-column  prop="type" label="型号">
                        </el-table-column>
                        <el-table-column  prop="unit" label="单位">
                        </el-table-column>
                        <el-table-column label="报价">
                            <template slot-scope="props">
                                <input type="number" v-model="props.row.quoted_price" placeholder="暂无数据" style="border:none;text-align:right;width:1rem;">
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="foot">
                    <p @click="baojia_tianxie_sure">保存</p>
                    <p @click="baojia_tianxie=false">取消</p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { get_prod_list_like, query_supply_list_of_self, query_supply_list_of_power, query_user_info, query_supply_list, query_quotation_his_date_list, query_quotation_his_list, insert_quotation_batch, query_supply_detail, query_quotation_list, query_all_prod_list_of_category, update_supply_prod_ids, query_prod_info_list, query_category_info_list, query_quotation_list2 } from '../../../api/api.js'
export default {
  name: 'supplierprice',
  data () {
    return {
      dangqian_yeshu: 1,
      dangqian_zongshu: 0,
      tiaoshu: 10,

      sousuo_text: '', // 需要搜索的字段
      gongying_shang_list: [], // 当前展示列表
      biao_tit: [
        {
          name: '供应商',
          con: 'supply_name'
        },
        {
          name: '产品编号',
          con: 'prod_code'
        },
        {
          name: '产品名称',
          con: 'prod_name'
        },
        {
          name: '规格',
          con: 'spec'
        },
        {
          name: '型号',
          con: 'type'
        },
        {
          name: '单位',
          con: 'unit'
        },
        {
          name: '报价（元）',
          con: 'quoted_price'
        }
      ],
      calendarData: [],

      chanpin: false, // 标签设置   打开选择产品
      xuanze_chanpin_id: '',
      sousuo_chanpin_text: '', // 搜索产品text
      chanpin_liebiao_list: [], // 产品列表
      qiye_chanpin_leixing_list: [], // 企业大类列表
      chanpin_xuanze: [],
      yixuan_chanpin: [],
      xiangxi_xinxi: [],
      xuan_zhong: [],

      gongying_shang_liebiao: [], // 用来选择供应商

      gongying_shang: false,
      gongying_shang_num: 0,
      dangqian_gongying_shang_chanpin_list: [],
      baojia_tianxie: false,

      lishi_rqi: '', // 历史日期
      rqi_list: [],

      dangqian_zhuangtai_num: '2', // 判断当前选的是看谁   2看我  1看员工  3看全部
      guanli: false
    }
  },
  mounted () {
  },
  components: {},
  created () {
    this.jichu()
  },
  watch: {
    dangqian_yeshu () {
      if (this.lishi_rqi.length != 0) {
        this.lishi_liebiao_jiekou()
      } else {
        this.liebiao_jiekou()
      }
    },
    tiaoshu () {
      if (this.lishi_rqi.length != 0) {
        this.lishi_liebiao_jiekou()
      } else {
        this.liebiao_jiekou()
      }
    },
    chanpin () {
      if (!this.chanpin) {
        this.xuanze_chanpin_id = ''
        this.sousuo_chanpin_text = ''// 搜索产品text
        this.chanpin_liebiao_list = []// 产品列表
        this.qiye_chanpin_leixing_list = []// 企业大类列表
        this.chanpin_xuanze = []
      }
    },
    lishi_rqi () {
      if (this.lishi_rqi.length != 0) {
        this.dangqian_yeshu = 1
        this.tiaoshu = 10
        this.lishi_liebiao_jiekou()
      } else {
        this.dangqian_yeshu = 1
        this.tiaoshu = 10
        this.liebiao_jiekou()
      }
    },
    dangqian_zhuangtai_num () {
      this.dangqian_yeshu = 1
      this.tiaoshu = 10
      this.liebiao_jiekou()
    }
  },
  methods: {
    // 表头颜色
    biaotou (row) { // 根据报警级别显示颜色
      return 'text-align:center;font-size:0.14rem;color:#1A2533;'
    },
    // color:#4C4A4D;font-size:0.12rem;
    liebiao (row) {
      return 'text-align:center;color:#1A2533;'
    },
    dianji_yeshu (val) {
      this.dangqian_yeshu = val
    },
    dianji_tiaoshu (val) {
      console.log(`每页 ${val} 条`)
      this.tiaoshu = val
      this.dangqian_yeshu = 1
    },
    dianji_xuanze_rli () {
      console.log(this.$refs.rli.formatedDate)
      this.lishi_rqi = this.$refs.rli.formatedDate
    },
    lishi_liebiao_jiekou () { // 历史列表接口
      query_quotation_his_list({
        data: {
          ent_id: this.$ent_id(),
          search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
          date_his: this.$refs.rli.formatedDate,
          page_size: this.tiaoshu + '',
          page_index: this.dangqian_yeshu + ''
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.list.length != 0) {
            this.dangqian_zongshu = date.total
            this.gongying_shang_list = date.list
          } else {
            this.dangqian_zongshu = 0
            this.gongying_shang_list = []
          }
        } else if (res.data.code == 500) {
          alert('系统报错')
        }
      })
    },
    jichu () {
      this.chaxun_quanxian()
      this.liebiao_jiekou()
      this.gongying_shang_liebiao_jiekou()
      this.lishi_rli_chaxun()
    },
    chaxun_quanxian () { // 查询权限
      query_user_info({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = res.data.body
          // console.log()
          if (date.staff_level == 1 || date.staff_level == 2 || date.dept_manager == 1) {
            this.guanli = true
          } else {
            this.guanli = false
          }
        }
      })
    },
    // 历史日期查询
    lishi_rli_chaxun () {
      query_quotation_his_date_list({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.rqi_list = date
        } else if (res.data.code == 500) {}
      })
    },
    xuanzhong (val) {
      // this.xuan_zhong = val;
      // console.log(val)
      if (val.length > 1) {
        this.$refs.gongying_shang_ref.clearSelection()
        this.$refs.gongying_shang_ref.toggleRowSelection(val.pop())
      } else {
        const list = []
        list.push(val.pop())
        this.xuan_zhong = list
      }
    },
    liebiao_jiekou () {
      query_quotation_list2({
        data: {
          ent_id: this.$ent_id(),
          search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
          page_size: this.tiaoshu + '',
          page_index: this.dangqian_yeshu + '',
          look_type: this.dangqian_zhuangtai_num + '',
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.list.length != 0) {
            this.dangqian_zongshu = date.total
            this.gongying_shang_list = date.list
          } else {
            this.dangqian_zongshu = 0
            this.gongying_shang_list = []
          }
        } else if (res.data.code == 500) {
          alert('系统报错')
        }
      })
    },
    // 供应商列表接口
    gongying_shang_liebiao_jiekou () {
      // query_supply_list({data:{
      //     ent_id:this.$ent_id()
      // }}).then(res=>{
      //     console.log(res)
      //     if(res.data.code==200){
      //         let date=JSON.parse(res.data.body.data)
      //         console.log(date)
      //         date.forEach(item=>{
      //             this.$set(item,'zhi',false)
      //         })
      //         date=this.$func.Es5duplicate(date,'supply_id')
      //         this.gongying_shang_liebiao=date
      //     }else if(res.data.code==500){}
      // })
      // query_supply_list_of_power
      query_supply_list_of_self({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        // search_str:val.text.length!=0?val.text:null,
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          // let date=JSON.parse(res.data.body.data)
          // this.$refs.xuanze.jichu('选择供应商',null,date,yixuan,null,null,'supply_name','supply_id')
          // this.$refs.xuanze.bangding('dangqian_gongying_shang')
          let date = JSON.parse(res.data.body.data)
          console.log(date)
          date.forEach(item => {
            this.$set(item, 'zhi', false)
          })
          date = this.$func.Es5duplicate(date, 'supply_id')
          this.gongying_shang_liebiao = date
        } else if (res.data.code == 500) {
          alert('查询供应商list报错')
        }
      })
    },
    biaoqian_shezhi () {
      console.log(this.xuan_zhong)
      if (this.xuan_zhong.length == 1 && this.xuan_zhong[0].staff_id == this.$jichuxinxi().user_id) {
        query_supply_detail({
          data: {
            ent_id: this.$ent_id(),
            supply_id: this.xuan_zhong[0].supply_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.yixuan_chanpin = date.prod_json != undefined ? JSON.parse(date.prod_json) : []
            this.dakai_chanpin()
          } else if (res.data.code == 500) {}
        })
      } else {
        this.$message({
          message: '请只选择一个属于自己的供应商',
          type: 'warning'
        })
      }
    },
    // 打开产品
    dakai_chanpin () {
      this.chanpin = true
      query_category_info_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          parent_id: '0',
          active: '1'
        }
      }).then(res => {
        console.log(4444444, res)
        if (res.data.code == 10193) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.qiye_chanpin_leixing_list = date
        } else if (res.data.code == 10194) {
        }
      })
      query_prod_info_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          cat_id: '0',
          active: '1'
        }
      }).then(res => {
        if (res.data.code == 10139) {
          const date = JSON.parse(res.data.body.data)
          this.no_xuan_chanpin(date)
        } else if (res.data.code == 10140) {
        }
      })
    },
    xuanze_chanpin_dalei () {
      if (this.xuanze_chanpin_id.length != 0) {
        query_all_prod_list_of_category({
          data: {
            ent_id: this.$ent_id(),
            cat_id: this.xuanze_chanpin_id + ''
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.no_xuan_chanpin(date)
          } else if (res.data.code == 500) {}
        })
      }
    },
    no_xuan_chanpin (date) {
      var a = date
      this.chanpin_liebiao_list = a
      console.log(this.xiangxi_xinxi)
      // 查看当前的大类
      const cat_ids = this.xuanze_chanpin_id
      let cat_names = ''
      this.qiye_chanpin_leixing_list.forEach(item => {
        if (item.cat_id == this.xuanze_chanpin_id) {
          cat_names = item.cat_name
        }
      })
      // if(this.xiangxi_xinxi[0].prod_ids!=undefined&&this.xiangxi_xinxi[0].prod_ids.length!=0){
      //     this.chanpin_liebiao_list.forEach(item=>{
      //         this.xiangxi_xinxi[0].prod_ids.split(',').forEach(ite=>{
      //             if(ite==item.prod_id){
      //                 // console.log(item)
      //                 // console.log(this.$refs.Tableb)
      //                 // this.$refs.Tableb.toggleRowSelection(item)
      //                 this.$set(item,'cat_ids',cat_ids)
      //                 this.$set(item,'cat_names',cat_names)
      //                 this.yixuan_chanpin.push(item)  //把本地存的那些产品标签拿出来
      //             }
      //         })
      //     })
      //     console.log(1111)
      // }
      this.$nextTick(function () {
        this.yixuan_chanpin = this.$func.Es5duplicate(this.yixuan_chanpin, 'prod_id')
        this.yixuan_chanpin.forEach(item => {
          this.chanpin_liebiao_list.forEach(ite => {
            if (ite.prod_id == item.prod_id) {
              this.$refs.Tableb.toggleRowSelection(ite)
            }
          })
        })
      })
      console.log(2222)
      this.$forceUpdate()
    },
    liebiao_yangshi (row) {
      return 'color:#4C4A4D;font-size:0.1rem;'
    },
    biaotou_yangshi (row) {
      return 'color:#1A2533;'
    },
    dianji_xuanzhong_chanpin (val) {
      this.chanpin_xuanze = val
    },
    dianji_xuanze_chanpin_baocun () {
      console.log(this.yixuan_chanpin) // 这是所选的产品
      console.log(this.xuan_zhong)

      const _this = this
      if (this.yixuan_chanpin.length != 0) {
        console.log(this.yixuan_chanpin)

        // 封为神的男人！nb
        query_quotation_list2({
          data: { // 获取该供应商所有的产品   提出来进行一起修改
            ent_id: this.$ent_id(),
            search_str: this.gongying_shang ? this.gongying_shang_liebiao[this.gongying_shang_num].supply_name : this.xuan_zhong[0].supply_name,
            page_size: '9999999',
            page_index: '1',
            look_type: this.dangqian_zhuangtai_num + '',
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            const yijing_cunzai = date.list // 已经存在的供应商产品报价
            const yixuan_chanpin = JSON.parse(JSON.stringify(_this.yixuan_chanpin))
            console.log(yixuan_chanpin)
            console.log(JSON.parse(JSON.stringify(_this.yixuan_chanpin)))
            console.log(_this.yixuan_chanpin)
            //
            const list = []
            yixuan_chanpin.forEach(item => {
              yijing_cunzai.forEach(ite => {
                if (item.prod_id == ite.prod_id) {
                  this.$set(item, 'quoted_price', ite.quoted_price)
                }
              })
            })
            yixuan_chanpin.forEach(item => {
              this.$set(item, 'supply_id', this.gongying_shang ? this.gongying_shang_liebiao[this.gongying_shang_num].supply_id : this.xuan_zhong[0].supply_id)
              this.$set(item, 'supply_name', this.gongying_shang ? this.gongying_shang_liebiao[this.gongying_shang_num].supply_name : this.xuan_zhong[0].supply_name)
              this.$set(item, 'quoted_price', item.quoted_price != undefined ? item.quoted_price : '')
              list.push(item)
            })

            this.dangqian_gongying_shang_chanpin_list = list
            this.baojia_tianxie = true
          } else if (res.data.code == 500) {
            alert('系统报错')
          }
        })
      } else {
        this.$message({
          message: '请选择产品进行下步操作',
          type: 'warning'
        })
      }

      // let shaixuan_dalei=this.$func.Es5duplicate(this.yixuan_chanpin,'cat_ids')
      // update_supply_prod_ids({data:{
      //     ent_id:this.$ent_id(),
      //     supply_id:this.gongying_shang_liebiao[this.gongying_shang_num].supply_id,//供应商id
      //     prod_ids:this.yixuan_chanpin.map(item=>item.prod_id).join(','),
      //     cat_id:shaixuan_dalei.map(item=>item.cat_ids).join(','),
      //     cat_name:shaixuan_dalei.map(item=>item.cat_name).join(','),
      //     user_id:this.$jichuxinxi().user_id,
      //     supply_name:this.xiangxi_xinxi[0].supply_name,
      // }}).then(res=>{
      //     console.log(res)
      //     if(res.data.code==200){
      //         this.$message({
      //             message: '保存成功',
      //             type: 'success'
      //         });
      //         this.liebiao_jiekou()
      //         this.chanpin=false
      //     }else if(res.data.code==500){}
      // })
    },
    xuanze_chanpin (rows, row) {
      console.log(rows)// 所有选中的人
      console.log(row)// 当前选中的人
      const ses = rows.length && rows.indexOf(row) !== -1 // 判断是否打钩
      console.log(ses)
      if (ses) {
        this.qiye_chanpin_leixing_list.forEach(item => {
          if (item.cat_id == this.xuanze_chanpin_id) {
            this.$set(row, 'cat_names', item.cat_name)
          }
        })
        this.$set(row, 'cat_ids', this.xuanze_chanpin_id)
        this.yixuan_chanpin.push(row)
        this.yixuan_chanpin = this.$func.Es5duplicate(this.yixuan_chanpin, 'prod_id')
      } else {
        for (let i = 0; i < this.yixuan_chanpin.length; i++) {
          if (this.yixuan_chanpin[i].prod_id == row.prod_id) {
            this.yixuan_chanpin.splice(i, 1)
            break
          }
        }
      }
    },
    xuanze_chanpin_quanxuan (rows, row) {
      console.log(rows)
      const cat_ids = this.xuanze_chanpin_id
      let cat_names = ''
      this.qiye_chanpin_leixing_list.forEach(item => {
        if (item.cat_id == this.xuanze_chanpin_id) {
          cat_names = item.cat_name
        }
      })
      if (rows.length != 0) {
        rows.forEach(item => {
          this.$set(item, 'cat_ids', cat_ids)
          this.$set(item, 'cat_names', cat_names)
          this.yixuan_chanpin.push(item)
        })
        this.yixuan_chanpin = this.$func.Es5duplicate(this.yixuan_chanpin, 'prod_id')
      } else {
        for (let i = 0; i < this.yixuan_chanpin.length; i++) {
          for (let a = 0; a < this.chanpin_liebiao_list.length; a++) {
            if (this.yixuan_chanpin[i].prod_id == this.chanpin_liebiao_list[a].prod_id) {
              this.yixuan_chanpin.splice(i, 1)
              i = i - 1
              break
            }
          }
        }
      }
    },
    shanchu_yixuan_chanpin (i, index) {
      this.chanpin_liebiao_list.forEach(ite => {
        if (ite.prod_id == i.prod_id) {
          this.$refs.Tableb.toggleRowSelection(ite)
        }
      })
      this.yixuan_chanpin.splice(index, 1)
    },
    dianji_queding_gongying_shang () {
      this.chaxun_gongying_shang_chanpin(this.gongying_shang_liebiao[this.gongying_shang_num].supply_id)
    },
    chaxun_gongying_shang_chanpin (supply_id) {
      query_supply_detail({
        data: {
          ent_id: this.$ent_id(),
          supply_id: supply_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.yixuan_chanpin = date.prod_json != undefined ? JSON.parse(date.prod_json) : []
          this.dakai_chanpin()
        } else if (res.data.code == 500) {}
      })
    },
    // 报价填写点击确定
    baojia_tianxie_sure () {
      const shaixuan_dalei = this.$func.Es5duplicate(this.yixuan_chanpin, 'cat_ids')
      console.log(shaixuan_dalei)

      update_supply_prod_ids({
        data: {
        // ent_id:this.$ent_id(),
        // supply_id:this.gongying_shang?this.gongying_shang_liebiao[this.gongying_shang_num].supply_id:this.xuan_zhong[0].supply_id,//供应商id
        // prod_ids:this.yixuan_chanpin.map(item=>item.prod_id).join(','),
        // cat_id:shaixuan_dalei.map(item=>item.cat_ids).join(','),
        // cat_name:shaixuan_dalei.map(item=>item.cat_name).join(','),
        // user_id:this.$jichuxinxi().user_id,
        // supply_name:this.gongying_shang_liebiao[this.gongying_shang_num].supply_name,
          ent_id: this.$ent_id(),
          supply_id: this.gongying_shang ? this.gongying_shang_liebiao[this.gongying_shang_num].supply_id : this.xuan_zhong[0].supply_id, // 供应商id
          prod_ids: this.yixuan_chanpin.map(item => item.prod_id).join(','),
          cat_id: shaixuan_dalei.map(item => item.cat_ids).join(','),
          cat_name: shaixuan_dalei.map(item => item.cat_name).join(','),
          user_id: this.$jichuxinxi().user_id,
          supply_name: this.gongying_shang_liebiao[this.gongying_shang_num].supply_name,
          prod_json: JSON.stringify(this.yixuan_chanpin)// 这个传所选的产品
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          // this.$message({
          //     message: '保存成功',
          //     type: 'success'
          // });
          // this.liebiao_jiekou()
          // this.chanpin=false
          // 2020、12/12又说放开  那无奈  放开
          // let list=[]
          // this.dangqian_gongying_shang_chanpin_list.forEach(item=>{
          //     if(item.quoted_price.length!=0){
          //         list.push(item)
          //     }
          // })
          insert_quotation_batch({
            data: {
              ent_id: this.$ent_id(),
              // supply_id:this.gongying_shang?this.gongying_shang_liebiao[this.gongying_shang_num].supply_id:this.xuan_zhong[0].supply_id,
              // supply_name:this.gongying_shang?this.gongying_shang_liebiao[this.gongying_shang_num].supply_name:this.xuan_zhong[0].supply_name,
              list: this.dangqian_gongying_shang_chanpin_list
            }
          }).then(ras => {
            console.log(ras)
            if (ras.data.code == 200) {
              this.$message({
                message: '保存成功',
                type: 'success'
              })
              this.liebiao_jiekou()
              this.chanpin = false
              this.gongying_shang = false
              this.baojia_tianxie = false
            } else if (ras.data.code == 500) {}
          })
        } else if (res.data.code == 500) {}
      })
    },
    dianji_baocun_baojia () {
      // alert('稍等 这块还少点东西')
      console.log(this.gongying_shang_list)

      insert_quotation_batch({
        data: {
          ent_id: this.$ent_id(),
          list: this.gongying_shang_list
        }
      }).then(ras => {
        console.log(ras)
        if (ras.data.code == 200) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.liebiao_jiekou()
        } else if (ras.data.code == 500) {}
      })
    },
    chanpin_sousuo () {
      // sousuo_chanpin_text
      if (this.sousuo_chanpin_text.length != 0) {
        get_prod_list_like({
          data: {
            active: '1',
            ent_id: this.$ent_id(),
            prod_name: this.sousuo_chanpin_text.length != 0 ? this.sousuo_chanpin_text : null,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10031) {
            const date = JSON.parse(res.data.body.data)
            this.no_xuan_chanpin(date)
            // for(let i=0;i<date.length;i++){
            //     for(let a=0;a<this.buneng_xuan_chanpin.length;a++){
            //         if(date[i].prod_id==this.buneng_xuan_chanpin[a]){
            //             date.splice(i,1)
            //             i=i-1
            //         }
            //     }
            // }
            // this.chanpin_liebiao_list=date
            // console.log(this.chanpin_liebiao_list)
            this.$forceUpdate()
          } else if (res.data.code == 10032) {}
        })
      } else {
        if (this.dangqian_chanpin.id.length != 0) {
          query_all_prod_list_of_category({
            data: {
              ent_id: this.$ent_id(),
              cat_id: this.dangqian_chanpin.id + ''
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              this.no_xuan_chanpin(date)
              // console.log(date)
              // console.log(this.buneng_xuan_chanpin)
              // for(let i=0;i<date.length;i++){
              //     for(let a=0;a<this.buneng_xuan_chanpin.length;a++){
              //         if(date[i].prod_id==this.buneng_xuan_chanpin[a]){
              //             date.splice(i,1)
              //             i=i-1
              //         }
              //     }
              // }
              // this.chanpin_liebiao_list=date
              // console.log(this.chanpin_liebiao_list)
              // this.$forceUpdate()
            } else if (res.data.code == 500) {}
          })
        } else {
          this.chanpin_liebiao_list = []
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import '../chanpin.scss';
 @import '../../../style/salesman.scss';
 .gongying_shang_table{
    input::-webkit-input-placeholder {
        text-align: center;
    }
 }
 .beijing_box_table{
    input::-webkit-input-placeholder {
        text-align: center;
    }
 }
 .beijing{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    z-index:100;
    background: rgba(0,0,0,0.5);
    .chanpin_leixing_box{
        min-height:6.8rem;
        width:10rem;
        background:#fff;
        position: relative;
        top:1.42rem;
        left:50%;
        margin-left: -5rem;
        border-radius: 0.04rem;
        margin-bottom: 1.4rem;
        .gongying_shang_box_tit{
            font-size: 0.18rem;
            color: #1a2533;
            font-weight: 600;
            text-align: center;
            padding: 0.15rem 0;
        }
        .guanbi_xuanze_chanpin{
            position: absolute;
            top:-0.2rem;
            right:-0.2rem;
            font-size: 0.45rem;
            color:rgb(252, 106, 106);
            cursor: pointer;
        }
        .mingxi_chanpin_yangshi{
            display: flex;
            align-items: center;
            padding: 0 0.2rem;
            flex-wrap: wrap;
            li{
                padding: 0.06rem 0.12rem;
                border:1px dashed  #c4c7cd;
                font-size: 0.12rem;
                color:#c4c7cd;
                position: relative;
                margin-right: 0.08rem;
                margin-bottom: 0.08rem;
                cursor: pointer;
                i{
                    font-size: 0.14rem;
                    color:#c4c7cd;
                    position: absolute;
                    top:-0.07rem;
                    right:-0.07rem;
                    display: none;
                }
                &:hover{
                    color:#9a86db;
                    border:1px dashed #9a86db;
                    i{
                        color:#9a86db;
                        display: block;
                    }
                }
            }
        }
        .beijing_box_tit{
            display: flex;
            align-items: center;
            font-size: 0.14rem;
            padding: 0.2rem;
            .shiyong_chanpin_dalei_kuang{
                position: relative;
                p{
                    height: 0.44rem;
                    width:1.4rem;
                    border:0.01rem solid #979797;
                    color:#1a2533;
                    font-size: 0.14rem;
                    padding:0 0.15rem;
                    cursor: pointer;
                    border-radius: 0.08rem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin:0 0.13rem;
                    line-height: 0.44rem;
                    // display: flex;
                    // align-items: center;
                    // span{
                    //     padding: 0.05rem 0.13rem;
                    //     border:1px solid #ccc;
                    //     font-size: 0.12rem;
                    //     color: #1a2533;
                    //     cursor: pointer;
                    //     border-radius: 0.03rem;
                    //     margin-right: 0.05rem;
                    //     height:0.2rem;
                    //     line-height: 0.2rem;
                    // }
                }
                .rolekanban_box_tit_right_box_con{
                    position: absolute;
                    width: 2.09rem;
                    height:2.84rem;
                    border:1px solid #ededed;
                    padding: 0.15rem 0.09rem;
                    background-color: #fff;
                    z-index:100;
                }
            }
            .shiyong_sousuo_chanpin{
                position: relative;
                input{
                    width:1.12rem;
                    height:0.42rem;
                    border:0.01rem solid #979797;
                    border-radius: 0.04rem;
                    padding: 0 0.4rem;
                }
                i{
                    font-size:0.16rem;
                    position: absolute;
                }
                .el-icon-search{
                    top:0.15rem;
                    left:0.15rem;
                }
                .el-icon-circle-close{
                    top:0.15rem;
                    right:0.15rem;
                    display: none;
                    cursor: pointer;
                }
                &:hover{
                    .el-icon-circle-close{
                        display: block;
                    }
                }
                margin-right:0.1rem;
            }
            .shiyong_chanpin_queding{
                padding: 0.05rem 0.13rem;
                background: #9b84dc;
                font-size: 0.12rem;
                color:#fff;
                cursor: pointer;
                border-radius: 0.03rem;
            }
        }
        .foot{
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                height: 0.4rem;
                width: 0.8rem;
                line-height: 0.4rem;
                text-align: center;
                margin: 0.15rem 0.2rem;
                cursor: pointer;
                border-radius: 0.04rem;
                font-size: 0.14rem;
                &:nth-child(1){
                    background-color:#9a86db;
                    border:1px solid #9a86db;
                    color: #fff;
                }
                &:nth-child(2){
                    border:1px solid #ccc;
                    color:#1a2533;
                }
            }
        }
    }
    .gongying_shang_box{
        height:6.8rem;
        width:10rem;
        background:#fff;
        position: relative;
        top:1.42rem;
        left:50%;
        margin-left: -5rem;
        border-radius: 0.04rem;
        margin-bottom: 1.4rem;
        .gongying_shang_box_tit{
            font-size: 0.18rem;
            color: #1a2533;
            font-weight: 600;
            text-align: center;
            padding: 0.15rem 0;
        }
        ul{
            width: 6.4rem;
            height:5.3rem;
            background-color: #f3f3f3;
            margin: 0 auto;
            overflow-y:auto;
            padding: 0.1rem 0.3rem;
            &::-webkit-scrollbar {
                width: 20px;
                height: 8px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 12px;
                border: 6px solid rgba(0, 0, 0, 0);
                box-shadow: 8px 0 0 #A5ADB7 inset;
            }

            &::-webkit-scrollbar-thumb:hover {
                box-shadow: 8px 0 0 #4A4A4A inset;
            }
            li{
                cursor: pointer;
                padding: 0.08rem;
                &:hover{
                    background-color: #e2e3e4;
                }
                img{
                    width: 0.2rem;
                    height: 0.2rem;
                    margin-right: 0.18rem;
                }
                display: flex;
                align-items: center;
            }
        }
        .foot{
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                height: 0.4rem;
                width: 0.8rem;
                line-height: 0.4rem;
                text-align: center;
                margin: 0.15rem 0.2rem;
                cursor: pointer;
                border-radius: 0.04rem;
                font-size: 0.14rem;
                &:nth-child(1){
                    background-color:#9a86db;
                    border:1px solid #9a86db;
                    color: #fff;
                }
                &:nth-child(2){
                    border:1px solid #ccc;
                    color:#1a2533;
                }
            }
        }
    }
}
</style>
